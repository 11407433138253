/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;1,400&display=swap');

html, body { height: 100%; width: 100%; margin:0px;}
body{ background: #f5f6fc;}
.tabsCOntainer{
    
    background: #fff;
    
    padding: 32px;
	border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
	margin-bottom: 50px;

}
.studenInfo {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;

    background: #eee;
}
.userNameField{
    color: #333;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
   line-height: 22px; text-align:right;
}
.userNameField span{ font-size: 14px; font-weight: normal;}

.ultimaConexio{
    color: #333;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
}

.ultimaConexioData{
    color: #333;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    width: 50%;
    text-align: end;
}
.licenceField{
    color: #333;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    width: 50%;
}
button.noboton {
    border: none;
    background: none;
    color: #00c7d1;
}

.sombra{-moz-box-shadow: inset 1px 1px 3px rgb(0 0 0 / 20%);
    -webkit-box-shadow: inset 1px 1px 3px rgb(0 0 0 / 20%);
    box-shadow: inset 1px 1px 3px rgb(0 0 0 / 20%);}


h2{font-family: Nunito;
    font-style: normal;
    font-weight: bold; color:#333; margin-bottom: 10px; font-size:2rem;}

h3{font-family: Nunito;
    font-style: normal;
    font-weight: bold; color:#333; margin-bottom: 10px; font-size:1.5rem; text-transform: uppercase;}
h4{font-family: Nunito;
    font-style: normal;
    font-weight: bold; color:#333; margin-bottom: 10px; font-size:1.6rem; font-weight: bold; margin-bottom: 20px;}
h5{color: black;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px; text-transform: uppercase;padding-bottom: 5px;
    border-bottom: 1px solid #333;}
.warning_text{
  height: 12%;
    width: 85%;
    
    text-align: center;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    align-items: center;
    text-align: left;
    color: red;
    overflow: hidden;
    display: block;
    margin: 20px auto;
}

.btn_metricas{
  color: #00C7D1;
    background-color: white;
    border: solid 1px #00C7D1 !important;
    border-radius: 8px;
    text-transform: uppercase;
    margin: 0 5px;
    padding: 5px 15px; font-family: Nunito; line-height:normal;
}

.btn_metricas.activo{
    background-color: #00C7D1; color:#fff;}

.btn_metricas:hover{
    color: #fff;
    background-color: #00C7D1;
    
}
p.bread a {
    color: #00C7D1;
    text-decoration: none;
    text-transform:uppercase;
	display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

p.bread a i{margin-right:5px;}
.botones{ position:absolute;
}
.popup {
    max-width: 500px;
}

@media screen and (max-width: 1024px){
.botones {
    position: relative;
    margin-bottom: 20px;
}
p.bread a {
    
	display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

}
@media screen and (max-width: 900px){
	
p.ultimaConexio strong {
    width: 100%;
		display: block;
}	
	
	
	
}


@media screen and (max-width: 500px){
	
	
	
	
	.ultimaConexio {
   
    text-align: left;
}
	.studenInfo {
    padding: 15px !important;
    margin: 0 !important;
  
}
	h2{text-align: left;display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;line-height: 24px;
    margin-bottom: 20
px
;} 
	h2 button {
    
    text-align: center;
    margin: 10px auto;
}
}
